@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Inter', sans-serif;
  }
  
  * {
    font-family: 'Inter', sans-serif;
  }